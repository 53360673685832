import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Menu, Grid } from "antd";
import IntlMessage from "../util-components/IntlMessage";
import Icon from "../util-components/Icon";
import { connect } from "react-redux";
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from "../../constants/ThemeConstant";
import utils from "../../utils";
import { onMobileNavToggle } from "../../redux/actions/Theme";
import {
  SettingOutlined,
  UserAddOutlined,
  UserSwitchOutlined,
  FolderViewOutlined,
  ScheduleOutlined,
  FileSyncOutlined,
  FileMarkdownOutlined,
  ApartmentOutlined,
  BankOutlined,
  SlidersOutlined,
  CloudServerOutlined,
  SnippetsOutlined,
  EyeOutlined,
  IdcardOutlined,
  SafetyCertificateOutlined,
  UsergroupAddOutlined,
  FieldTimeOutlined,
  CalendarOutlined,
  AuditOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import store from "../../redux/store";
import { APP_PREFIX_PATH } from "../../configs/AppConfig";
import {
  APPROVAL_RELOAD,
  EVENTS_RELOAD,
  MY_COMPLIANCE_RELOAD,
} from "actions/types";

const { SubMenu } = Menu;
const { useBreakpoint } = Grid;

const superUserNavTree = [
  {
    key: "general-settings",
    path: `${APP_PREFIX_PATH}/`,
    title: "sidenav.general-settings",
    icon: SettingOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "compliance-master",
        path: `${APP_PREFIX_PATH}/compliance-master`,
        title: "sidenav.compliance-master",
        icon: FileMarkdownOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "matrics",
        path: `${APP_PREFIX_PATH}/matrics`,
        title: "sidenav.matrics",
        icon: SlidersOutlined,
        breadcrumb: true,
        active: true,
        submenu: [
          {
            key: "Dashboards",
            path: `${APP_PREFIX_PATH}/matrices/dashboard`,
            title: "sidenav.matrics-dashboard",
            icon: EyeOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "Reports",
            path: `${APP_PREFIX_PATH}/matrices/reports`,
            title: "sidenav.matrics-reports",
            icon: SnippetsOutlined,
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
      {
        key: "my-compliance-view",
        path: `${APP_PREFIX_PATH}/my-compliance-view`,
        title: "sidenav.my-compliance-view",
        icon: FolderViewOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "myCompTime",
            path: `${APP_PREFIX_PATH}/my-compliance-view/time`,
            title: "sidenav.my-compliance-view-time",
            icon: FieldTimeOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "myCompEvent",
            path: `${APP_PREFIX_PATH}/my-compliance-view/events`,
            title: "sidenav.my-compliance-view-events",
            icon: CalendarOutlined,
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
      {
        key: "compliance-view",
        path: `${APP_PREFIX_PATH}/compliance-calender-view`,
        title: "sidenav.compliance-view-task",
        icon: IdcardOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "auditor-action",
        path: `${APP_PREFIX_PATH}/auditing-action`,
        title: "sidenav.auditor-action",
        icon: AuditOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "compliance-approval",
        path: `${APP_PREFIX_PATH}/compliance-approval`,
        title: "sidenav.compliance-approval",
        icon: CheckCircleOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "events",
        path: `${APP_PREFIX_PATH}/events`,
        title: "sidenav.events",
        icon: CalendarOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "Certificates",
        path: `${APP_PREFIX_PATH}/matrices/certificate`,
        title: "sidenav.matrics-certificates",
        icon: SafetyCertificateOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "due-delegation",
        path: `${APP_PREFIX_PATH}/due-delegation`,
        title: "sidenav.due-delegation",
        icon: UserAddOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "dueDelegationTime",
            path: `${APP_PREFIX_PATH}/due-delegation/time`,
            title: "sidenav.due-delegation-time",
            icon: FieldTimeOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "dueDelegationEvent",
            path: `${APP_PREFIX_PATH}/due-delegation/events`,
            title: "sidenav.due-delegation-events",
            icon: CalendarOutlined,
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
    ],
  },
  {
    key: "application-settings",
    path: `${APP_PREFIX_PATH}/`,
    title: "sidenav.applicaiton-settings",
    icon: SettingOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "settings",
        path: `${APP_PREFIX_PATH}/`,
        title: "sidenav.settings",
        icon: SettingOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "compliance-admin",
            path: `${APP_PREFIX_PATH}/compliance-admin`,
            title: "sidenav.compliance-admin",
            icon: UserSwitchOutlined,
            breadcrumb: true,
            submenu: [],
          },

          {
            key: "company-management",
            path: `${APP_PREFIX_PATH}/company-management`,
            title: "sidenav.company-management",
            icon: BankOutlined,
            breadcrumb: true,
            submenu: [],
          },

          {
            key: "user-management",
            path: `${APP_PREFIX_PATH}/user-management`,
            title: "sidenav.user-management",
            icon: UserAddOutlined,
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
    ],
  },
  {
    key: "client-settings",
    path: `${APP_PREFIX_PATH}/`,
    title: "sidenav.client-settings",
    icon: ScheduleOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "company-settings",
        path: `${APP_PREFIX_PATH}/`,
        title: "sidenav.company-settings",
        icon: CloudServerOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "project-management",
            path: `${APP_PREFIX_PATH}/division-management`,
            title: "sidenav.project-management",
            icon: ApartmentOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "auditor-management",
            path: `${APP_PREFIX_PATH}/auditor-management`,
            title: "sidenav.auditor-management",
            icon: AuditOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "company-compliance-mapping",
            path: `${APP_PREFIX_PATH}/company-compliance`,
            title: "sidenav.company-compliance-mapping",
            icon: FileSyncOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "division-compliance",
            path: `${APP_PREFIX_PATH}/division-compliance`,
            title: "sidenav.division-compliance",
            icon: UsergroupAddOutlined,
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
    ],
  },
];
const complianceAdminTree = [
  {
    key: "general-settings",
    path: `${APP_PREFIX_PATH}/`,
    title: "sidenav.general-settings",
    icon: SettingOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "compliance-master",
        path: `${APP_PREFIX_PATH}/compliance-master`,
        title: "sidenav.compliance-master",
        icon: FileMarkdownOutlined,
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
  {
    key: "application-settings",
    path: `${APP_PREFIX_PATH}/`,
    title: "sidenav.applicaiton-settings",
    icon: SettingOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "settings",
        path: `${APP_PREFIX_PATH}/`,
        title: "sidenav.settings",
        icon: SettingOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "company-management",
            path: `${APP_PREFIX_PATH}/company-management`,
            title: "sidenav.company-management",
            icon: BankOutlined,
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
    ],
  },
];
const companyNavTree = [
  {
    key: "general-settings",
    path: `${APP_PREFIX_PATH}/`,
    title: "sidenav.general-settings",
    icon: SettingOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "matrics",
        path: `${APP_PREFIX_PATH}/matrics`,
        title: "sidenav.matrics",
        icon: SlidersOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "Dashboards",
            path: `${APP_PREFIX_PATH}/matrices/dashboard`,
            title: "sidenav.matrics-dashboard",
            icon: EyeOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "Reports",
            path: `${APP_PREFIX_PATH}/matrices/reports`,
            title: "sidenav.matrics-reports",
            icon: SnippetsOutlined,
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
      {
        key: "my-compliance-view",
        path: `${APP_PREFIX_PATH}/my-compliance-view`,
        title: "sidenav.my-compliance-view",
        icon: FolderViewOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "myCompTime",
            path: `${APP_PREFIX_PATH}/my-compliance-view/time`,
            title: "sidenav.my-compliance-view-time",
            icon: FieldTimeOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "myCompEvent",
            path: `${APP_PREFIX_PATH}/my-compliance-view/events`,
            title: "sidenav.my-compliance-view-events",
            icon: CalendarOutlined,
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
      {
        key: "compliance-view",
        path: `${APP_PREFIX_PATH}/compliance-calender-view`,
        title: "sidenav.compliance-view-task",
        icon: IdcardOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "auditor-action",
        path: `${APP_PREFIX_PATH}/auditing-action`,
        title: "sidenav.auditor-action",
        icon: AuditOutlined,
        breadcrumb: true,
        submenu: [],
      },

      {
        key: "compliance-approval",
        path: `${APP_PREFIX_PATH}/compliance-approval`,
        title: "sidenav.compliance-approval",
        icon: CheckCircleOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "events",
        path: `${APP_PREFIX_PATH}/events`,
        title: "sidenav.events",
        icon: CalendarOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "Certificates",
        path: `${APP_PREFIX_PATH}/matrices/certificate`,
        title: "sidenav.matrics-certificates",
        icon: SafetyCertificateOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "due-delegation",
        path: `${APP_PREFIX_PATH}/due-delegation`,
        title: "sidenav.due-delegation",
        icon: UserAddOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "dueDelegationTime",
            path: `${APP_PREFIX_PATH}/due-delegation/time`,
            title: "sidenav.due-delegation-time",
            icon: FieldTimeOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "dueDelegationEvent",
            path: `${APP_PREFIX_PATH}/due-delegation/events`,
            title: "sidenav.due-delegation-events",
            icon: CalendarOutlined,
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
    ],
  },
  {
    key: "client-settings",
    path: `${APP_PREFIX_PATH}/`,
    title: "sidenav.client-settings",
    icon: ScheduleOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "company-settings",
        path: `${APP_PREFIX_PATH}/`,
        title: "sidenav.company-settings",
        icon: CloudServerOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "project-management",
            path: `${APP_PREFIX_PATH}/division-management`,
            title: "sidenav.project-management",
            icon: ApartmentOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "auditor-management",
            path: `${APP_PREFIX_PATH}/auditor-management`,
            title: "sidenav.auditor-management",
            icon: AuditOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "company-compliance-mapping",
            path: `${APP_PREFIX_PATH}/company-compliance`,
            title: "sidenav.company-compliance-mapping",
            icon: FileSyncOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "division-compliance",
            path: `${APP_PREFIX_PATH}/division-compliance`,
            title: "sidenav.division-compliance",
            icon: UsergroupAddOutlined,
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
    ],
  },
];
const divisionNavTree = [
  {
    key: "general-settings",
    path: `${APP_PREFIX_PATH}/`,
    title: "sidenav.general-settings",
    icon: SettingOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "matrics",
        path: `${APP_PREFIX_PATH}/matrics`,
        title: "sidenav.matrics",
        icon: SlidersOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "Dashboards",
            path: `${APP_PREFIX_PATH}/matrices/dashboard`,
            title: "sidenav.matrics-dashboard",
            icon: EyeOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "Reports",
            path: `${APP_PREFIX_PATH}/matrices/reports`,
            title: "sidenav.matrics-reports",
            icon: SnippetsOutlined,
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
      {
        key: "my-compliance-view",
        path: `${APP_PREFIX_PATH}/my-compliance-view`,
        title: "sidenav.my-compliance-view",
        icon: FolderViewOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "myCompTime",
            path: `${APP_PREFIX_PATH}/my-compliance-view/time`,
            title: "sidenav.my-compliance-view-time",
            icon: FieldTimeOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "myCompEvent",
            path: `${APP_PREFIX_PATH}/my-compliance-view/events`,
            title: "sidenav.my-compliance-view-events",
            icon: CalendarOutlined,
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
      {
        key: "compliance-view",
        path: `${APP_PREFIX_PATH}/compliance-calender-view`,
        title: "sidenav.compliance-view-task",
        icon: IdcardOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "auditor-action",
        path: `${APP_PREFIX_PATH}/auditing-action`,
        title: "sidenav.auditor-action",
        icon: AuditOutlined,
        breadcrumb: true,
        submenu: [],
      },

      {
        key: "compliance-approval",
        path: `${APP_PREFIX_PATH}/compliance-approval`,
        title: "sidenav.compliance-approval",
        icon: CheckCircleOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "events",
        path: `${APP_PREFIX_PATH}/events`,
        title: "sidenav.events",
        icon: CalendarOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "Certificates",
        path: `${APP_PREFIX_PATH}/matrices/certificate`,
        title: "sidenav.matrics-certificates",
        icon: SafetyCertificateOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "due-delegation",
        path: `${APP_PREFIX_PATH}/due-delegation`,
        title: "sidenav.due-delegation",
        icon: UserAddOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "dueDelegationTime",
            path: `${APP_PREFIX_PATH}/due-delegation/time`,
            title: "sidenav.due-delegation-time",
            icon: FieldTimeOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "dueDelegationEvent",
            path: `${APP_PREFIX_PATH}/due-delegation/events`,
            title: "sidenav.due-delegation-events",
            icon: CalendarOutlined,
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
    ],
  },
  {
    key: "client-settings",
    path: `${APP_PREFIX_PATH}/`,
    title: "sidenav.client-settings",
    icon: ScheduleOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "company-settings",
        path: `${APP_PREFIX_PATH}/`,
        title: "sidenav.company-settings",
        icon: CloudServerOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "company-compliance-mapping",
            path: `${APP_PREFIX_PATH}/company-compliance`,
            title: "sidenav.company-compliance-mapping",
            icon: FileSyncOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "division-compliance",
            path: `${APP_PREFIX_PATH}/division-compliance`,
            title: "sidenav.division-compliance",
            icon: UsergroupAddOutlined,
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
    ],
  },
];
const userNavTree = [
  {
    key: "general-settings",
    path: `${APP_PREFIX_PATH}/`,
    title: "sidenav.general-settings",
    icon: SettingOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "matrics",
        path: `${APP_PREFIX_PATH}/matrics`,
        title: "sidenav.matrics",
        icon: SlidersOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "Dashboards",
            path: `${APP_PREFIX_PATH}/matrices/dashboard`,
            title: "sidenav.matrics-dashboard",
            icon: EyeOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "Reports",
            path: `${APP_PREFIX_PATH}/matrices/reports`,
            title: "sidenav.matrics-reports",
            icon: SnippetsOutlined,
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
      {
        key: "my-compliance-view",
        path: `${APP_PREFIX_PATH}/my-compliance-view`,
        title: "sidenav.my-compliance-view",
        icon: FolderViewOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "myCompTime",
            path: `${APP_PREFIX_PATH}/my-compliance-view/time`,
            title: "sidenav.my-compliance-view-time",
            icon: FieldTimeOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "myCompEvent",
            path: `${APP_PREFIX_PATH}/my-compliance-view/events`,
            title: "sidenav.my-compliance-view-events",
            icon: CalendarOutlined,
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
      {
        key: "compliance-view",
        path: `${APP_PREFIX_PATH}/compliance-calender-view`,
        title: "sidenav.compliance-view-task",
        icon: IdcardOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "auditor-action",
        path: `${APP_PREFIX_PATH}/auditing-action`,
        title: "sidenav.auditor-action",
        icon: AuditOutlined,
        breadcrumb: true,
        submenu: [],
      },

      {
        key: "compliance-approval",
        path: `${APP_PREFIX_PATH}/compliance-approval`,
        title: "sidenav.compliance-approval",
        icon: CheckCircleOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "events",
        path: `${APP_PREFIX_PATH}/events`,
        title: "sidenav.events",
        icon: CalendarOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "Certificates",
        path: `${APP_PREFIX_PATH}/matrices/certificate`,
        title: "sidenav.matrics-certificates",
        icon: SafetyCertificateOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "due-delegation",
        path: `${APP_PREFIX_PATH}/due-delegation`,
        title: "sidenav.due-delegation",
        icon: UserAddOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "dueDelegationTime",
            path: `${APP_PREFIX_PATH}/due-delegation/time`,
            title: "sidenav.due-delegation-time",
            icon: FieldTimeOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "dueDelegationEvent",
            path: `${APP_PREFIX_PATH}/due-delegation/events`,
            title: "sidenav.due-delegation-events",
            icon: CalendarOutlined,
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
    ],
  },
];

// const superUserNavTreew = [
//   {
//     key: "general-settings",
//     path: `${APP_PREFIX_PATH}/`,
//     title: "sidenav.general-settings",
//     icon: SettingOutlined,
//     breadcrumb: true,
//     submenu: [
//       {
//         key: "compliance-master",
//         path: `${APP_PREFIX_PATH}/compliance-master`,
//         title: "sidenav.compliance-master",
//         icon: FileMarkdownOutlined,
//         breadcrumb: true,
//         submenu: [],
//       },
//       {
//         key: "matrics",
//         path: `${APP_PREFIX_PATH}/matrics`,
//         title: "sidenav.matrics",
//         icon: SlidersOutlined,
//         breadcrumb: true,
//         active: true,
//         submenu: [
//           {
//             key: "Dashboards",
//             path: `${APP_PREFIX_PATH}/matrices/dashboard`,
//             title: "sidenav.matrics-dashboard",
//             icon: EyeOutlined,
//             breadcrumb: true,
//             submenu: [],
//           },
//           {
//             key: "Reports",
//             path: `${APP_PREFIX_PATH}/matrices/reports`,
//             title: "sidenav.matrics-reports",
//             icon: SnippetsOutlined,
//             breadcrumb: true,
//             submenu: [],
//           },
//         ],
//       },
//       {
//         key: "my-compliance-view",
//         path: `${APP_PREFIX_PATH}/my-compliance-view`,
//         title: "sidenav.my-compliance-view",
//         icon: FolderViewOutlined,
//         breadcrumb: true,
//         submenu: [
//           {
//             key: "myCompTime",
//             path: `${APP_PREFIX_PATH}/my-compliance-view/time`,
//             title: "sidenav.my-compliance-view-time",
//             icon: FieldTimeOutlined,
//             breadcrumb: true,
//             submenu: [],
//           },
//           {
//             key: "myCompEvent",
//             path: `${APP_PREFIX_PATH}/my-compliance-view/events`,
//             title: "sidenav.my-compliance-view-events",
//             icon: CalendarOutlined,
//             breadcrumb: true,
//             submenu: [],
//           },
//         ],
//       },
//       {
//         key: "compliance-view",
//         path: `${APP_PREFIX_PATH}/compliance-calender-view`,
//         title: "sidenav.compliance-view-task",
//         icon: IdcardOutlined,
//         breadcrumb: true,
//         submenu: [],
//       },
//       {
//         key: "auditor-action",
//         path: `${APP_PREFIX_PATH}/auditing-action`,
//         title: "sidenav.auditor-action",
//         icon: QuestionCircleOutlined,
//         breadcrumb: true,
//         submenu: [],
//       },

//       {
//         key: "events",
//         path: `${APP_PREFIX_PATH}/events`,
//         title: "sidenav.events",
//         icon: QuestionCircleOutlined,
//         breadcrumb: true,
//         submenu: [],
//       },
//       {
//         key: "Certificates",
//         path: `${APP_PREFIX_PATH}/matrices/certificate`,
//         title: "sidenav.matrics-certificates",
//         icon: SafetyCertificateOutlined,
//         breadcrumb: true,
//         submenu: [],
//       },
//     ],
//   },
//   {
//     key: "application-settings",
//     path: `${APP_PREFIX_PATH}/`,
//     title: "sidenav.applicaiton-settings",
//     icon: SettingOutlined,
//     breadcrumb: true,
//     submenu: [
//       {
//         key: "settings",
//         path: `${APP_PREFIX_PATH}/`,
//         title: "sidenav.settings",
//         icon: SettingOutlined,
//         breadcrumb: true,
//         submenu: [
//           {
//             key: "compliance-admin",
//             path: `${APP_PREFIX_PATH}/compliance-admin`,
//             title: "sidenav.compliance-admin",
//             icon: UserSwitchOutlined,
//             breadcrumb: true,
//             submenu: [],
//           },

//           {
//             key: "company-management",
//             path: `${APP_PREFIX_PATH}/company-management`,
//             title: "sidenav.company-management",
//             icon: BankOutlined,
//             breadcrumb: true,
//             submenu: [],
//           },

//           {
//             key: "user-management",
//             path: `${APP_PREFIX_PATH}/user-management`,
//             title: "sidenav.user-management",
//             icon: UserAddOutlined,
//             breadcrumb: true,
//             submenu: [],
//           },
//         ],
//       },
//     ],
//   },
//   {
//     key: "client-settings",
//     path: `${APP_PREFIX_PATH}/`,
//     title: "sidenav.client-settings",
//     icon: ScheduleOutlined,
//     breadcrumb: true,
//     submenu: [
//       {
//         key: "company-settings",
//         path: `${APP_PREFIX_PATH}/`,
//         title: "sidenav.company-settings",
//         icon: CloudServerOutlined,
//         breadcrumb: true,
//         submenu: [
//           {
//             key: "project-management",
//             path: `${APP_PREFIX_PATH}/division-management`,
//             title: "sidenav.project-management",
//             icon: ApartmentOutlined,
//             breadcrumb: true,
//             submenu: [],
//           },
//           {
//             key: "auditor-management",
//             path: `${APP_PREFIX_PATH}/auditor-management`,
//             title: "sidenav.auditor-management",
//             icon: AuditOutlined,
//             breadcrumb: true,
//             submenu: [],
//           },
//           {
//             key: "company-compliance-mapping",
//             path: `${APP_PREFIX_PATH}/company-compliance`,
//             title: "sidenav.company-compliance-mapping",
//             icon: FileSyncOutlined,
//             breadcrumb: true,
//             submenu: [],
//           },
//           {
//             key: "division-compliance",
//             path: `${APP_PREFIX_PATH}/division-compliance`,
//             title: "sidenav.division-compliance",
//             icon: UsergroupAddOutlined,
//             breadcrumb: true,
//             submenu: [],
//           },
//         ],
//       },
//     ],
//   },
// ];
// const complianceAdminTreew = [
//   {
//     key: "general-settings",
//     path: `${APP_PREFIX_PATH}/`,
//     title: "sidenav.general-settings",
//     icon: SettingOutlined,
//     breadcrumb: true,
//     submenu: [
//       {
//         key: "compliance-master",
//         path: `${APP_PREFIX_PATH}/compliance-master`,
//         title: "sidenav.compliance-master",
//         icon: FileMarkdownOutlined,
//         breadcrumb: true,
//         submenu: [],
//       },
//     ],
//   },
//   {
//     key: "application-settings",
//     path: `${APP_PREFIX_PATH}/`,
//     title: "sidenav.applicaiton-settings",
//     icon: SettingOutlined,
//     breadcrumb: true,
//     submenu: [
//       {
//         key: "settings",
//         path: `${APP_PREFIX_PATH}/`,
//         title: "sidenav.settings",
//         icon: SettingOutlined,
//         breadcrumb: true,
//         submenu: [
//           {
//             key: "company-management",
//             path: `${APP_PREFIX_PATH}/company-management`,
//             title: "sidenav.company-management",
//             icon: BankOutlined,
//             breadcrumb: true,
//             submenu: [],
//           },
//         ],
//       },
//     ],
//   },
// ];
// const companyNavTreew = [
//   {
//     key: "general-settings",
//     path: `${APP_PREFIX_PATH}/`,
//     title: "sidenav.general-settings",
//     icon: SettingOutlined,
//     breadcrumb: true,
//     submenu: [
//       {
//         key: "matrics",
//         path: `${APP_PREFIX_PATH}/matrics`,
//         title: "sidenav.matrics",
//         icon: SlidersOutlined,
//         breadcrumb: true,
//         submenu: [
//           {
//             key: "Dashboards",
//             path: `${APP_PREFIX_PATH}/matrices/dashboard`,
//             title: "sidenav.matrics-dashboard",
//             icon: EyeOutlined,
//             breadcrumb: true,
//             submenu: [],
//           },
//           {
//             key: "Reports",
//             path: `${APP_PREFIX_PATH}/matrices/reports`,
//             title: "sidenav.matrics-reports",
//             icon: SnippetsOutlined,
//             breadcrumb: true,
//             submenu: [],
//           },
//         ],
//       },
//       {
//         key: "my-compliance-view",
//         path: `${APP_PREFIX_PATH}/my-compliance-view`,
//         title: "sidenav.my-compliance-view",
//         icon: FolderViewOutlined,
//         breadcrumb: true,
//         submenu: [
//           {
//             key: "myCompTime",
//             path: `${APP_PREFIX_PATH}/my-compliance-view/time`,
//             title: "sidenav.my-compliance-view-time",
//             icon: FieldTimeOutlined,
//             breadcrumb: true,
//             submenu: [],
//           },
//           {
//             key: "myCompEvent",
//             path: `${APP_PREFIX_PATH}/my-compliance-view/events`,
//             title: "sidenav.my-compliance-view-events",
//             icon: CalendarOutlined,
//             breadcrumb: true,
//             submenu: [],
//           },
//         ],
//       },
//       {
//         key: "compliance-view",
//         path: `${APP_PREFIX_PATH}/compliance-calender-view`,
//         title: "sidenav.compliance-view-task",
//         icon: IdcardOutlined,
//         breadcrumb: true,
//         submenu: [],
//       },
//       {
//         key: "auditor-action",
//         path: `${APP_PREFIX_PATH}/auditing-action`,
//         title: "sidenav.auditor-action",
//         icon: QuestionCircleOutlined,
//         breadcrumb: true,
//         submenu: [],
//       },

//       {
//         key: "events",
//         path: `${APP_PREFIX_PATH}/events`,
//         title: "sidenav.events",
//         icon: QuestionCircleOutlined,
//         breadcrumb: true,
//         submenu: [],
//       },
//       {
//         key: "Certificates",
//         path: `${APP_PREFIX_PATH}/matrices/certificate`,
//         title: "sidenav.matrics-certificates",
//         icon: SafetyCertificateOutlined,
//         breadcrumb: true,
//         submenu: [],
//       },
//     ],
//   },
//   {
//     key: "client-settings",
//     path: `${APP_PREFIX_PATH}/`,
//     title: "sidenav.client-settings",
//     icon: ScheduleOutlined,
//     breadcrumb: true,
//     submenu: [
//       {
//         key: "company-settings",
//         path: `${APP_PREFIX_PATH}/`,
//         title: "sidenav.company-settings",
//         icon: CloudServerOutlined,
//         breadcrumb: true,
//         submenu: [
//           {
//             key: "project-management",
//             path: `${APP_PREFIX_PATH}/division-management`,
//             title: "sidenav.project-management",
//             icon: ApartmentOutlined,
//             breadcrumb: true,
//             submenu: [],
//           },
//           {
//             key: "auditor-management",
//             path: `${APP_PREFIX_PATH}/auditor-management`,
//             title: "sidenav.auditor-management",
//             icon: AuditOutlined,
//             breadcrumb: true,
//             submenu: [],
//           },
//           {
//             key: "company-compliance-mapping",
//             path: `${APP_PREFIX_PATH}/company-compliance`,
//             title: "sidenav.company-compliance-mapping",
//             icon: FileSyncOutlined,
//             breadcrumb: true,
//             submenu: [],
//           },
//           {
//             key: "division-compliance",
//             path: `${APP_PREFIX_PATH}/division-compliance`,
//             title: "sidenav.division-compliance",
//             icon: UsergroupAddOutlined,
//             breadcrumb: true,
//             submenu: [],
//           },
//         ],
//       },
//     ],
//   },
// ];
// const divisionNavTreew = [
//   {
//     key: "general-settings",
//     path: `${APP_PREFIX_PATH}/`,
//     title: "sidenav.general-settings",
//     icon: SettingOutlined,
//     breadcrumb: true,
//     submenu: [
//       {
//         key: "matrics",
//         path: `${APP_PREFIX_PATH}/matrics`,
//         title: "sidenav.matrics",
//         icon: SlidersOutlined,
//         breadcrumb: true,
//         submenu: [
//           {
//             key: "Dashboards",
//             path: `${APP_PREFIX_PATH}/matrices/dashboard`,
//             title: "sidenav.matrics-dashboard",
//             icon: EyeOutlined,
//             breadcrumb: true,
//             submenu: [],
//           },
//           {
//             key: "Reports",
//             path: `${APP_PREFIX_PATH}/matrices/reports`,
//             title: "sidenav.matrics-reports",
//             icon: SnippetsOutlined,
//             breadcrumb: true,
//             submenu: [],
//           },
//         ],
//       },
//       {
//         key: "my-compliance-view",
//         path: `${APP_PREFIX_PATH}/my-compliance-view`,
//         title: "sidenav.my-compliance-view",
//         icon: FolderViewOutlined,
//         breadcrumb: true,
//         submenu: [
//           {
//             key: "myCompTime",
//             path: `${APP_PREFIX_PATH}/my-compliance-view/time`,
//             title: "sidenav.my-compliance-view-time",
//             icon: FieldTimeOutlined,
//             breadcrumb: true,
//             submenu: [],
//           },
//           {
//             key: "myCompEvent",
//             path: `${APP_PREFIX_PATH}/my-compliance-view/events`,
//             title: "sidenav.my-compliance-view-events",
//             icon: CalendarOutlined,
//             breadcrumb: true,
//             submenu: [],
//           },
//         ],
//       },
//       {
//         key: "compliance-view",
//         path: `${APP_PREFIX_PATH}/compliance-calender-view`,
//         title: "sidenav.compliance-view-task",
//         icon: IdcardOutlined,
//         breadcrumb: true,
//         submenu: [],
//       },
//       {
//         key: "auditor-action",
//         path: `${APP_PREFIX_PATH}/auditing-action`,
//         title: "sidenav.auditor-action",
//         icon: QuestionCircleOutlined,
//         breadcrumb: true,
//         submenu: [],
//       },

//       {
//         key: "events",
//         path: `${APP_PREFIX_PATH}/events`,
//         title: "sidenav.events",
//         icon: QuestionCircleOutlined,
//         breadcrumb: true,
//         submenu: [],
//       },
//       {
//         key: "Certificates",
//         path: `${APP_PREFIX_PATH}/matrices/certificate`,
//         title: "sidenav.matrics-certificates",
//         icon: SafetyCertificateOutlined,
//         breadcrumb: true,
//         submenu: [],
//       },
//     ],
//   },
//   {
//     key: "client-settings",
//     path: `${APP_PREFIX_PATH}/`,
//     title: "sidenav.client-settings",
//     icon: ScheduleOutlined,
//     breadcrumb: true,
//     submenu: [
//       {
//         key: "company-settings",
//         path: `${APP_PREFIX_PATH}/`,
//         title: "sidenav.company-settings",
//         icon: CloudServerOutlined,
//         breadcrumb: true,
//         submenu: [
//           {
//             key: "company-compliance-mapping",
//             path: `${APP_PREFIX_PATH}/company-compliance`,
//             title: "sidenav.company-compliance-mapping",
//             icon: FileSyncOutlined,
//             breadcrumb: true,
//             submenu: [],
//           },
//           {
//             key: "division-compliance",
//             path: `${APP_PREFIX_PATH}/division-compliance`,
//             title: "sidenav.division-compliance",
//             icon: UsergroupAddOutlined,
//             breadcrumb: true,
//             submenu: [],
//           },
//         ],
//       },
//     ],
//   },
// ];
// const userNavTreew = [
//   {
//     key: "general-settings",
//     path: `${APP_PREFIX_PATH}/`,
//     title: "sidenav.general-settings",
//     icon: SettingOutlined,
//     breadcrumb: true,
//     submenu: [
//       {
//         key: "matrics",
//         path: `${APP_PREFIX_PATH}/matrics`,
//         title: "sidenav.matrics",
//         icon: SlidersOutlined,
//         breadcrumb: true,
//         submenu: [
//           {
//             key: "Dashboards",
//             path: `${APP_PREFIX_PATH}/matrices/dashboard`,
//             title: "sidenav.matrics-dashboard",
//             icon: EyeOutlined,
//             breadcrumb: true,
//             submenu: [],
//           },
//           {
//             key: "Reports",
//             path: `${APP_PREFIX_PATH}/matrices/reports`,
//             title: "sidenav.matrics-reports",
//             icon: SnippetsOutlined,
//             breadcrumb: true,
//             submenu: [],
//           },
//         ],
//       },
//       {
//         key: "my-compliance-view",
//         path: `${APP_PREFIX_PATH}/my-compliance-view`,
//         title: "sidenav.my-compliance-view",
//         icon: FolderViewOutlined,
//         breadcrumb: true,
//         submenu: [
//           {
//             key: "myCompTime",
//             path: `${APP_PREFIX_PATH}/my-compliance-view/time`,
//             title: "sidenav.my-compliance-view-time",
//             icon: FieldTimeOutlined,
//             breadcrumb: true,
//             submenu: [],
//           },
//           {
//             key: "myCompEvent",
//             path: `${APP_PREFIX_PATH}/my-compliance-view/events`,
//             title: "sidenav.my-compliance-view-events",
//             icon: CalendarOutlined,
//             breadcrumb: true,
//             submenu: [],
//           },
//         ],
//       },
//       {
//         key: "compliance-view",
//         path: `${APP_PREFIX_PATH}/compliance-calender-view`,
//         title: "sidenav.compliance-view-task",
//         icon: IdcardOutlined,
//         breadcrumb: true,
//         submenu: [],
//       },
//       {
//         key: "auditor-action",
//         path: `${APP_PREFIX_PATH}/auditing-action`,
//         title: "sidenav.auditor-action",
//         icon: QuestionCircleOutlined,
//         breadcrumb: true,
//         submenu: [],
//       },

//       {
//         key: "events",
//         path: `${APP_PREFIX_PATH}/events`,
//         title: "sidenav.events",
//         icon: QuestionCircleOutlined,
//         breadcrumb: true,
//         submenu: [],
//       },
//       {
//         key: "Certificates",
//         path: `${APP_PREFIX_PATH}/matrices/certificate`,
//         title: "sidenav.matrics-certificates",
//         icon: SafetyCertificateOutlined,
//         breadcrumb: true,
//         submenu: [],
//       },
//     ],
//   },
// ];

const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

const setDefaultOpen = (key) => {
  let keyList = [];
  let keyString = "";
  if (key) {
    const arr = key.split("-");
    for (let index = 0; index < arr.length; index++) {
      const elm = arr[index];
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
      keyList.push(keyString);
    }
  }
  return keyList;
};

const SideNavContent = (props) => {
  const {
    sideNavTheme,
    routeInfo,
    hideGroupTitle,
    localization,
    onMobileNavToggle,
    data,
    customeUserRole,
    divisionRole,
    companyRole,
  } = props;
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes("lg");
  const [navContent, setnavContent] = useState([]);

  const history = useHistory(); // This is for React Router v5

  useEffect(() => {
    history.listen((location, action) => {
      if (
        location.pathname.includes("my-compliance") &&
        sessionStorage.getItem("myComplianceFilter") === "1"
      ) {
        store.dispatch({ type: MY_COMPLIANCE_RELOAD, payload: true });
      }
      if (
        location.pathname.includes("compliance-approval") &&
        sessionStorage.getItem("ApprovalFilter") === "1"
      ) {
        store.dispatch({ type: APPROVAL_RELOAD, payload: true });
      }
      if (
        location.pathname.includes("events") &&
        sessionStorage.getItem("eventsFilters") === "1"
      ) {
        store.dispatch({ type: EVENTS_RELOAD, payload: true });
      }
    });
  }, [history]);

  useEffect(() => {
    if (divisionRole === null && companyRole === null) {
      if (data.role === "USER") {
        setnavContent(userNavTree);
      }
      if (data.role === "AUDITOR") {
        setnavContent(userNavTree);
      }
      if (data.role === "PROJECT-ADMIN") {
        setnavContent(divisionNavTree);
      }
      if (data.role === "COMPANY-ADMIN") {
        setnavContent(companyNavTree);
      }
      if (data.role === "SUPER-USER") {
        setnavContent(superUserNavTree);
      }
      if (data.role === "COMPLIANCE-ADMIN") {
        setnavContent(complianceAdminTree);
      }
      if (data.role === "CXO-ADMIN") {
        setnavContent(userNavTree);
      }
    } else if (divisionRole === null && companyRole !== null) {
      if (companyRole === "USER") {
        setnavContent(userNavTree);
      }
      if (companyRole === "AUDITOR") {
        setnavContent(userNavTree);
      }
      if (companyRole === "PROJECT-ADMIN") {
        setnavContent(divisionNavTree);
      }
      if (companyRole === "COMPANY-ADMIN") {
        setnavContent(companyNavTree);
      }
      if (companyRole === "SUPER-USER") {
        setnavContent(superUserNavTree);
      }
      if (companyRole === "COMPLIANCE-ADMIN") {
        setnavContent(complianceAdminTree);
      }
      if (companyRole === "CXO-ADMIN") {
        setnavContent(userNavTree);
      }
    } else {
      if (divisionRole === "USER") {
        setnavContent(userNavTree);
      }
      if (divisionRole === "AUDITOR") {
        setnavContent(userNavTree);
      }
      if (divisionRole === "PROJECT-ADMIN") {
        setnavContent(divisionNavTree);
      }
      if (divisionRole === "COMPANY-ADMIN") {
        setnavContent(companyNavTree);
      }
      if (divisionRole === "SUPER-USER") {
        setnavContent(superUserNavTree);
      }
      if (divisionRole === "COMPLIANCE-ADMIN") {
        setnavContent(complianceAdminTree);
      }
      if (divisionRole === "CXO-ADMIN") {
        setnavContent(userNavTree);
      }
    }
  }, [data, customeUserRole, divisionRole, companyRole]);

  const closeMobileNav = () => {
    if (isMobile) {
      onMobileNavToggle(false);
    }
  };
  return (
    <Menu
      theme={sideNavTheme === SIDE_NAV_LIGHT ? "light" : "dark"}
      mode="inline"
      style={{ height: "100%", borderRight: 0 }}
      defaultSelectedKeys={[routeInfo?.key]}
      defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
      className={hideGroupTitle ? "hide-group-title" : "tt"}
    >
      {navContent.map((menu) =>
        menu.submenu.length > 0 ? (
          <Menu.ItemGroup
            key={menu.key}
            title={setLocale(localization, menu.title)}
          >
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  icon={
                    subMenuFirst.icon ? (
                      <Icon type={subMenuFirst?.icon} />
                    ) : null
                  }
                  key={subMenuFirst.key}
                  title={setLocale(localization, subMenuFirst.title)}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) => (
                    <Menu.Item
                      key={subMenuSecond.key}
                      style={{ paddingLeft: "34px" }}
                    >
                      {subMenuSecond.icon ? (
                        <Icon type={subMenuSecond?.icon} />
                      ) : null}
                      <span>
                        {setLocale(localization, subMenuSecond.title)}
                      </span>
                      <Link
                        onClick={() => closeMobileNav()}
                        to={subMenuSecond.path}
                      />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? <Icon type={subMenuFirst.icon} /> : null}
                  <span>{setLocale(localization, subMenuFirst.title)}</span>
                  <Link
                    onClick={() => closeMobileNav()}
                    to={subMenuFirst.path}
                  />
                </Menu.Item>
              )
            )}
          </Menu.ItemGroup>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? (
              <Link onClick={() => closeMobileNav()} to={menu.path} />
            ) : null}
          </Menu.Item>
        )
      )}
    </Menu>
  );
};

const TopNavContent = (props) => {
  const { topNavColor, localization, data } = props;
  const [navContent, setnavContent] = useState([]);
  useEffect(() => {
    if (data.role === "USER") {
      setnavContent(userNavTree);
    }
    if (data.role === "PROJECT-ADMIN") {
      setnavContent(divisionNavTree);
    }
    if (data.role === "COMPANY-ADMIN") {
      setnavContent(companyNavTree);
    }
    if (data.role === "SUPER-USER") {
      setnavContent(superUserNavTree);
    }
  }, [data]);

  return (
    <Menu mode="horizontal" style={{ backgroundColor: topNavColor }}>
      {navContent.map((menu) =>
        menu.submenu.length > 0 ? (
          <SubMenu
            key={menu.key}
            popupClassName="top-nav-menu"
            title={
              <span>
                {menu.icon ? <Icon type={menu?.icon} /> : null}
                <span>{setLocale(localization, menu.title)}</span>
              </span>
            }
          >
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  key={subMenuFirst.key}
                  popupClassName="top-nav-menu"
                  icon={
                    subMenuFirst.icon ? (
                      <Icon type={subMenuFirst?.icon} />
                    ) : null
                  }
                  title={setLocale(localization, subMenuFirst.title)}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) => (
                    <Menu.Item key={subMenuSecond.key}>
                      <span>
                        {setLocale(localization, subMenuSecond.title)}
                      </span>
                      <Link to={subMenuSecond.path} />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? (
                    <Icon type={subMenuFirst?.icon} />
                  ) : null}
                  <span>{setLocale(localization, subMenuFirst.title)}</span>
                  <Link to={subMenuFirst.path} />
                </Menu.Item>
              )
            )}
          </SubMenu>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? <Link to={menu.path} /> : null}
          </Menu.Item>
        )
      )}
    </Menu>
  );
};

const MenuContent = (props) => {
  return props.type === NAV_TYPE_SIDE ? (
    <SideNavContent {...props} />
  ) : (
    <TopNavContent {...props} />
  );
};

const mapStateToProps = ({ theme, auth, userCompliance }) => {
  const { sideNavTheme, topNavColor } = theme;
  const { data, customeUserRole, divisionRole, companyRole } = auth;
  const { showApprovalTab } = userCompliance;
  return {
    sideNavTheme,
    topNavColor,
    data,
    customeUserRole,
    showApprovalTab,
    divisionRole,
    companyRole,
  };
};

export default connect(mapStateToProps, { onMobileNavToggle })(MenuContent);
